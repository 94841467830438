import React, { useState, useEffect } from "react"
import A11yButton from "../a11yButton"

const Footer = ({ showBelow }) => {
  const [show, setShow] = useState(showBelow ? false : true)

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll)
      return () => window.removeEventListener(`scroll`, handleScroll)
    }
  })

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })
  }

  return (
    <footer className="rn-footer-area bg-color-extra05">
      {/* <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner">
                                <h3 className="title">Get Repped <br /> In Tech</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      <div className="wrapper">
        <div className="copyright ptb--60">
          <div className="container">
            <div className="row">
              <div className="col-lg-9">
                <p className="copyright-text">
                  © {new Date().getFullYear()}, Dr. Teresa Vasquez. All rights
                  reserved.
                </p>
              </div>
              <div className="col-lg-3">
                <div className="back-to-top">
                  <A11yButton className="backtop" elementType="div" onClick={handleClick}>
                    <span className="top"></span>
                  </A11yButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
