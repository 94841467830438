import React from 'react';

export default function A11yButton({
  elementType,
  onClick,
  ...props
}) {

  function keyDownA11y(handler) {
    return function onKeyDown(event) {
      if (
        ['keydown', 'keypress'].includes(event.type)
        && ['Enter', ' '].includes(event.key)
      ) {
        handler();
      }
    }
  }
  
  return React.createElement(elementType, {
    ...props,
    onClick,
    onKeyDown: keyDownA11y(onClick),
    role: 'button',
    tabIndex: 0,
  });
}
