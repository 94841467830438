import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { slugify } from "../utils/utilityFunctions"

const CategoriesWidget = ({ showTitle, showAllBtn }) => {
  const categoryQueryData = useStaticQuery(graphql`
    query CategoriesWidgetQuery {
      allMarkdownRemark(sort: { order: ASC, fields: frontmatter___date }) {
        edges {
          node {
            frontmatter {
              category
            }
          }
        }
      }
    }
  `)
  const blogs = categoryQueryData.allMarkdownRemark.edges
  const categories = [
    ...new Set(blogs.map(blog => blog.node.frontmatter.category)),
  ]

  return (
    <div className="row">
      <div className="col-lg-12">
        {showTitle && (
          <div className="section-title">
            <span className="subtitle">Topics</span>
          </div>
        )}

        <ul className="category-list">
          {categories && (
            <>
              {categories.map((category, index) => (
                <Link key={index} to={`/category/${slugify(category)}`}>
                  <li className="category-list__item">
                    <span className="value">{category}</span>
                  </li>
                </Link>
              ))}
            </>
          )}
          {showAllBtn && (
            <Link to="/blog">
              <li className="category-list__item">
                <span className="value">View All Posts</span>
              </li>
            </Link>
          )}
        </ul>
      </div>
    </div>
  )
}

export default CategoriesWidget
