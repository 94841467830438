import React, { Fragment, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Scrollspy from "react-scrollspy"
import CategoriesWidget from "../categoriesWidget"
import A11yButton from "../a11yButton"

// Start Header Area
const Header = () => {
  const headerQuery = useStaticQuery(graphql`
    query headerQuery {
      allMenuJson {
        nodes {
          title
          path
        }
      }
      file(relativePath: { eq: "images/logo/drtw.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const [isOverlayOpen, setIsOverlayOpen] = useState(false)

  useEffect(() => {
    if (!isOverlayOpen) {
      document
        .querySelector(".trigger-popup-menu")
        .classList.remove("overlay-wrapper-open")
      document
        .querySelector(".hambergur-menu")
        .classList.remove("hambergur-menu-open")
    }
  })

  const onMenuToggleClick = () => {
    setIsOverlayOpen(prevState => !prevState)
    document
      .querySelector(".trigger-popup-menu")
      .classList.toggle("overlay-wrapper-open")
    document
      .querySelector(".hambergur-menu")
      .classList.toggle("hambergur-menu-open")
  }
  const drtLogo = headerQuery.file.childImageSharp.fixed

  return (
    <Fragment>
      <header className="rn-header header-default header-transparent">
        <div className="header-inner">
          {/* Header Logo  */}
          <div className="header-left">
            <div className="logo">
              <Link to="/">
                <Img fixed={drtLogo} />
              </Link>
            </div>
          </div>
          {/* Main Menu  */}
          <A11yButton
            className="header-right"
            elementType="div"
            onClick={onMenuToggleClick}
          >
            <div className="hambergur-menu">
              <div className="hamburger-box">
                <div className="hamburger-inner"></div>
              </div>
            </div>
          </A11yButton>
          {/* Start Social Area  */}
        </div>
      </header>
      <div className="trigger-popup-menu">
        <div className="menu_full">
          <div className="menu_wrapper">
            <Scrollspy
              className="popup_mainmenu"
              items={["home", "portfolio", "blog", "contact"]}
              currentClassName="is-current"
              offset={-200}
            >
              <li>
                <CategoriesWidget showTitle={true} />
              </li>
              <li>
                <Link
                  className="menu-hover-link"
                  to="/"
                  onClick={onMenuToggleClick}
                >
                  <span className="hover-item">
                    <span data-text="Home">Home</span>
                  </span>
                </Link>
              </li>
              <li>
                <a
                  className="menu-hover-link"
                  href="https://youtube.com/playlist?list=PLUqiTr8vlNlMBxR_hUa1xkQ-nclmV7JbN"
                  target="_blank"
                  onClick={onMenuToggleClick}
                >
                  <span className="hover-item">
                    <span data-text="The Show">The Show</span>
                  </span>
                </a>
              </li>
              <li>
                <Link
                  className="menu-hover-link"
                  to="/about"
                  onClick={onMenuToggleClick}
                >
                  <span className="hover-item">
                    <span data-text="About">About</span>
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  className="menu-hover-link"
                  to="/blog"
                  onClick={onMenuToggleClick}
                >
                  <span className="hover-item">
                    <span data-text="Blog">Blog</span>
                  </span>
                </Link>
              </li>
              <li>
                <a
                  className="menu-hover-link"
                  href="https://anchor.fm/reppedintech"
                  target="_blank"
                  onClick={onMenuToggleClick}
                >
                  <span className="hover-item">
                    <span data-text="Podcast">Podcast</span>
                  </span>
                </a>
              </li>
            </Scrollspy>
          </div>
          <A11yButton
            className="trigger_closer"
            elementType="div"
            onClick={onMenuToggleClick}
          >
            <span className="text">Close</span>
            <span className="icon"></span>
          </A11yButton>
        </div>
      </div>
    </Fragment>
  )
}
// End Header Area

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
